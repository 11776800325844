export const defaultCountries = [
  { country: "Australia", country_code: "AU" },
  { country: "Austria", country_code: "AT" },
  { country: "Belgium", country_code: "BE" },
  { country: "Brazil", country_code: "BR" },
  { country: "Bulgaria", country_code: "BG" },
  { country: "Canada", country_code: "CA" },
  { country: "Chile", country_code: "CL" },
  { country: "China", country_code: "CN" },
  { country: "Colombia", country_code: "CO" },
  { country: "Croatia", country_code: "HR" },
  { country: "Cyprus", country_code: "CY" },
  { country: "Czech Republic", country_code: "CZ" },
  { country: "Denmark", country_code: "DK" },
  { country: "Estonia", country_code: "EE" },
  { country: "Euro", country_code: "EUR" },
  { country: "Finland", country_code: "FI" },
  { country: "France", country_code: "FR" },
  { country: "Germany", country_code: "DE" },
  { country: "Greece", country_code: "GR" },
  { country: "Hong Kong", country_code: "HK" },
  { country: "Hungary", country_code: "HU" },
  { country: "Iceland", country_code: "IS" },
  { country: "India", country_code: "IN" },
  { country: "Ireland", country_code: "IE" },
  { country: "Italy", country_code: "IT" },
  { country: "Japan", country_code: "JP" },
  { country: "Latvia", country_code: "LV" },
  { country: "Liechtenstein", country_code: "LI" },
  { country: "Lithuania", country_code: "LT" },
  { country: "Luxembourg", country_code: "LU" },
  { country: "Malaysia", country_code: "MY" },
  { country: "Malta", country_code: "MT" },
  { country: "Mexico", country_code: "MX" },
  { country: "Netherlands", country_code: "NL" },
  { country: "New Zealand", country_code: "NZ" },
  { country: "Norway", country_code: "NO" },
  { country: "Poland", country_code: "PL" },
  { country: "Portugal", country_code: "PT" },
  { country: "Romania", country_code: "RO" },
  { country: "Russia", country_code: "RU" },
  { country: "Singapore", country_code: "SG" },
  { country: "Slovakia", country_code: "SK" },
  { country: "Slovenia", country_code: "SI" },
  { country: "South Africa", country_code: "ZA" },
  { country: "South Korea", country_code: "KR" },
  { country: "Spain", country_code: "ES" },
  { country: "Sweden", country_code: "SE" },
  { country: "Switzerland", country_code: "CH" },
  { country: "Taiwan", country_code: "TW" },
  { country: "Thailand", country_code: "TH" },
  { country: "Turkey", country_code: "TR" },
  { country: "United Kingdom", country_code: "GB" },
  { country: "United States", country_code: "US" },
];

export const defaultData = [
  {
    id: "AU",
    date: new Date("2020-06-30T00:00:00.000Z"),
    data: [
      { x: 1, y: 0.00052 },
      { x: 2, y: 0.00096 },
      { x: 3, y: 0.00138 },
      { x: 4, y: 0.00202 },
      { x: 5, y: 0.0028 },
      { x: 6, y: 0.00368 },
      { x: 7, y: 0.00466 },
      { x: 8, y: 0.00571 },
      { x: 9, y: 0.00671 },
      { x: 10, y: 0.00758 },
      { x: 11, y: 0.00828 },
      { x: 12, y: 0.00884 },
      { x: 13, y: 0.00927 },
      { x: 14, y: 0.0096 },
      { x: 15, y: 0.00986 },
      { x: 16, y: 0.01006 },
      { x: 17, y: 0.01022 },
      { x: 18, y: 0.01037 },
      { x: 19, y: 0.0105 },
      { x: 20, y: 0.01062 },
      { x: 21, y: 0.01074 },
      { x: 22, y: 0.01084 },
      { x: 23, y: 0.01091 },
      { x: 24, y: 0.01092 },
      { x: 25, y: 0.01087 },
      { x: 26, y: 0.01077 },
      { x: 27, y: 0.01065 },
      { x: 28, y: 0.01057 },
      { x: 29, y: 0.01055 },
      { x: 30, y: 0.01062 },
      { x: 31, y: 0.01079 },
      { x: 32, y: 0.01105 },
      { x: 33, y: 0.01136 },
      { x: 34, y: 0.01172 },
      { x: 35, y: 0.01211 },
      { x: 36, y: 0.01253 },
      { x: 37, y: 0.01295 },
      { x: 38, y: 0.01339 },
      { x: 39, y: 0.01383 },
      { x: 40, y: 0.01427 },
      { x: 41, y: 0.0147 },
      { x: 42, y: 0.01513 },
      { x: 43, y: 0.01556 },
      { x: 44, y: 0.01597 },
      { x: 45, y: 0.01638 },
      { x: 46, y: 0.01678 },
      { x: 47, y: 0.01717 },
      { x: 48, y: 0.01754 },
      { x: 49, y: 0.01791 },
      { x: 50, y: 0.01827 },
      { x: 51, y: 0.01862 },
      { x: 52, y: 0.01895 },
      { x: 53, y: 0.01928 },
      { x: 54, y: 0.0196 },
      { x: 55, y: 0.01991 },
      { x: 56, y: 0.02021 },
      { x: 57, y: 0.0205 },
      { x: 58, y: 0.02078 },
      { x: 59, y: 0.02105 },
      { x: 60, y: 0.02132 },
      { x: 61, y: 0.02157 },
      { x: 62, y: 0.02182 },
      { x: 63, y: 0.02207 },
      { x: 64, y: 0.0223 },
      { x: 65, y: 0.02253 },
      { x: 66, y: 0.02275 },
      { x: 67, y: 0.02297 },
      { x: 68, y: 0.02318 },
      { x: 69, y: 0.02338 },
      { x: 70, y: 0.02358 },
      { x: 71, y: 0.02378 },
      { x: 72, y: 0.02396 },
      { x: 73, y: 0.02415 },
      { x: 74, y: 0.02433 },
      { x: 75, y: 0.0245 },
      { x: 76, y: 0.02467 },
      { x: 77, y: 0.02483 },
      { x: 78, y: 0.025 },
      { x: 79, y: 0.02515 },
      { x: 80, y: 0.02531 },
      { x: 81, y: 0.02545 },
      { x: 82, y: 0.0256 },
      { x: 83, y: 0.02574 },
      { x: 84, y: 0.02588 },
      { x: 85, y: 0.02602 },
      { x: 86, y: 0.02615 },
      { x: 87, y: 0.02628 },
      { x: 88, y: 0.02641 },
      { x: 89, y: 0.02653 },
      { x: 90, y: 0.02665 },
      { x: 91, y: 0.02677 },
      { x: 92, y: 0.02689 },
      { x: 93, y: 0.027 },
      { x: 94, y: 0.02711 },
      { x: 95, y: 0.02722 },
      { x: 96, y: 0.02733 },
      { x: 97, y: 0.02743 },
      { x: 98, y: 0.02753 },
      { x: 99, y: 0.02763 },
      { x: 100, y: 0.02773 },
      { x: 101, y: 0.02783 },
      { x: 102, y: 0.02792 },
      { x: 103, y: 0.02801 },
      { x: 104, y: 0.0281 },
      { x: 105, y: 0.02819 },
      { x: 106, y: 0.02828 },
      { x: 107, y: 0.02837 },
      { x: 108, y: 0.02845 },
      { x: 109, y: 0.02853 },
      { x: 110, y: 0.02861 },
      { x: 111, y: 0.02869 },
      { x: 112, y: 0.02877 },
      { x: 113, y: 0.02885 },
      { x: 114, y: 0.02893 },
      { x: 115, y: 0.029 },
      { x: 116, y: 0.02907 },
      { x: 117, y: 0.02914 },
      { x: 118, y: 0.02921 },
      { x: 119, y: 0.02928 },
      { x: 120, y: 0.02935 },
      { x: 121, y: 0.02942 },
      { x: 122, y: 0.02949 },
      { x: 123, y: 0.02955 },
      { x: 124, y: 0.02961 },
      { x: 125, y: 0.02968 },
      { x: 126, y: 0.02974 },
      { x: 127, y: 0.0298 },
      { x: 128, y: 0.02986 },
      { x: 129, y: 0.02992 },
      { x: 130, y: 0.02998 },
      { x: 131, y: 0.03003 },
      { x: 132, y: 0.03009 },
      { x: 133, y: 0.03015 },
      { x: 134, y: 0.0302 },
      { x: 135, y: 0.03025 },
      { x: 136, y: 0.03031 },
      { x: 137, y: 0.03036 },
      { x: 138, y: 0.03041 },
      { x: 139, y: 0.03046 },
      { x: 140, y: 0.03051 },
      { x: 141, y: 0.03056 },
      { x: 142, y: 0.03061 },
      { x: 143, y: 0.03066 },
      { x: 144, y: 0.03071 },
      { x: 145, y: 0.03075 },
      { x: 146, y: 0.0308 },
      { x: 147, y: 0.03084 },
      { x: 148, y: 0.03089 },
      { x: 149, y: 0.03093 },
      { x: 150, y: 0.03098 },
    ],
  },
  {
    id: "CH",
    date: new Date("2020-06-30T00:00:00.000Z"),
    data: [
      { x: 1, y: -0.00737 },
      { x: 2, y: -0.00736 },
      { x: 3, y: -0.00724 },
      { x: 4, y: -0.00693 },
      { x: 5, y: -0.00642 },
      { x: 6, y: -0.00577 },
      { x: 7, y: -0.00513 },
      { x: 8, y: -0.00461 },
      { x: 9, y: -0.00425 },
      { x: 10, y: -0.00408 },
      { x: 11, y: -0.00407 },
      { x: 12, y: -0.00417 },
      { x: 13, y: -0.0043 },
      { x: 14, y: -0.00443 },
      { x: 15, y: -0.00452 },
      { x: 16, y: -0.00458 },
      { x: 17, y: -0.00459 },
      { x: 18, y: -0.00454 },
      { x: 19, y: -0.00443 },
      { x: 20, y: -0.00427 },
      { x: 21, y: -0.00405 },
      { x: 22, y: -0.00377 },
      { x: 23, y: -0.00343 },
      { x: 24, y: -0.00303 },
      { x: 25, y: -0.00258 },
      { x: 26, y: -0.00207 },
      { x: 27, y: -0.00151 },
      { x: 28, y: -0.00093 },
      { x: 29, y: -0.00033 },
      { x: 30, y: 0.00027 },
      { x: 31, y: 0.00087 },
      { x: 32, y: 0.00147 },
      { x: 33, y: 0.00206 },
      { x: 34, y: 0.00263 },
      { x: 35, y: 0.0032 },
      { x: 36, y: 0.00375 },
      { x: 37, y: 0.00428 },
      { x: 38, y: 0.0048 },
      { x: 39, y: 0.0053 },
      { x: 40, y: 0.00579 },
      { x: 41, y: 0.00625 },
      { x: 42, y: 0.00671 },
      { x: 43, y: 0.00715 },
      { x: 44, y: 0.00757 },
      { x: 45, y: 0.00798 },
      { x: 46, y: 0.00837 },
      { x: 47, y: 0.00875 },
      { x: 48, y: 0.00912 },
      { x: 49, y: 0.00948 },
      { x: 50, y: 0.00982 },
      { x: 51, y: 0.01015 },
      { x: 52, y: 0.01047 },
      { x: 53, y: 0.01078 },
      { x: 54, y: 0.01108 },
      { x: 55, y: 0.01137 },
      { x: 56, y: 0.01165 },
      { x: 57, y: 0.01192 },
      { x: 58, y: 0.01218 },
      { x: 59, y: 0.01244 },
      { x: 60, y: 0.01268 },
      { x: 61, y: 0.01292 },
      { x: 62, y: 0.01315 },
      { x: 63, y: 0.01338 },
      { x: 64, y: 0.01359 },
      { x: 65, y: 0.0138 },
      { x: 66, y: 0.01401 },
      { x: 67, y: 0.01421 },
      { x: 68, y: 0.0144 },
      { x: 69, y: 0.01459 },
      { x: 70, y: 0.01477 },
      { x: 71, y: 0.01495 },
      { x: 72, y: 0.01512 },
      { x: 73, y: 0.01529 },
      { x: 74, y: 0.01545 },
      { x: 75, y: 0.01561 },
      { x: 76, y: 0.01577 },
      { x: 77, y: 0.01592 },
      { x: 78, y: 0.01607 },
      { x: 79, y: 0.01621 },
      { x: 80, y: 0.01635 },
      { x: 81, y: 0.01649 },
      { x: 82, y: 0.01662 },
      { x: 83, y: 0.01675 },
      { x: 84, y: 0.01688 },
      { x: 85, y: 0.017 },
      { x: 86, y: 0.01712 },
      { x: 87, y: 0.01724 },
      { x: 88, y: 0.01736 },
      { x: 89, y: 0.01747 },
      { x: 90, y: 0.01758 },
      { x: 91, y: 0.01769 },
      { x: 92, y: 0.0178 },
      { x: 93, y: 0.0179 },
      { x: 94, y: 0.018 },
      { x: 95, y: 0.0181 },
      { x: 96, y: 0.0182 },
      { x: 97, y: 0.01829 },
      { x: 98, y: 0.01839 },
      { x: 99, y: 0.01848 },
      { x: 100, y: 0.01857 },
      { x: 101, y: 0.01866 },
      { x: 102, y: 0.01874 },
      { x: 103, y: 0.01883 },
      { x: 104, y: 0.01891 },
      { x: 105, y: 0.01899 },
      { x: 106, y: 0.01907 },
      { x: 107, y: 0.01915 },
      { x: 108, y: 0.01923 },
      { x: 109, y: 0.0193 },
      { x: 110, y: 0.01938 },
      { x: 111, y: 0.01945 },
      { x: 112, y: 0.01952 },
      { x: 113, y: 0.01959 },
      { x: 114, y: 0.01966 },
      { x: 115, y: 0.01973 },
      { x: 116, y: 0.0198 },
      { x: 117, y: 0.01986 },
      { x: 118, y: 0.01993 },
      { x: 119, y: 0.01999 },
      { x: 120, y: 0.02005 },
      { x: 121, y: 0.02011 },
      { x: 122, y: 0.02017 },
      { x: 123, y: 0.02023 },
      { x: 124, y: 0.02029 },
      { x: 125, y: 0.02035 },
      { x: 126, y: 0.02041 },
      { x: 127, y: 0.02046 },
      { x: 128, y: 0.02052 },
      { x: 129, y: 0.02057 },
      { x: 130, y: 0.02062 },
      { x: 131, y: 0.02067 },
      { x: 132, y: 0.02073 },
      { x: 133, y: 0.02078 },
      { x: 134, y: 0.02083 },
      { x: 135, y: 0.02088 },
      { x: 136, y: 0.02093 },
      { x: 137, y: 0.02097 },
      { x: 138, y: 0.02102 },
      { x: 139, y: 0.02107 },
      { x: 140, y: 0.02111 },
      { x: 141, y: 0.02116 },
      { x: 142, y: 0.0212 },
      { x: 143, y: 0.02125 },
      { x: 144, y: 0.02129 },
      { x: 145, y: 0.02133 },
      { x: 146, y: 0.02137 },
      { x: 147, y: 0.02142 },
      { x: 148, y: 0.02146 },
      { x: 149, y: 0.0215 },
      { x: 150, y: 0.02154 },
    ],
  },
  {
    id: "CN",
    date: new Date("2020-06-30T00:00:00.000Z"),
    data: [
      { x: 1, y: 0.01792 },
      { x: 2, y: 0.01843 },
      { x: 3, y: 0.01914 },
      { x: 4, y: 0.01996 },
      { x: 5, y: 0.02083 },
      { x: 6, y: 0.02174 },
      { x: 7, y: 0.02264 },
      { x: 8, y: 0.02352 },
      { x: 9, y: 0.02437 },
      { x: 10, y: 0.02518 },
      { x: 11, y: 0.02595 },
      { x: 12, y: 0.02669 },
      { x: 13, y: 0.02739 },
      { x: 14, y: 0.02805 },
      { x: 15, y: 0.02868 },
      { x: 16, y: 0.02927 },
      { x: 17, y: 0.02983 },
      { x: 18, y: 0.03036 },
      { x: 19, y: 0.03086 },
      { x: 20, y: 0.03133 },
      { x: 21, y: 0.03178 },
      { x: 22, y: 0.03221 },
      { x: 23, y: 0.03261 },
      { x: 24, y: 0.03299 },
      { x: 25, y: 0.03336 },
      { x: 26, y: 0.03371 },
      { x: 27, y: 0.03403 },
      { x: 28, y: 0.03435 },
      { x: 29, y: 0.03465 },
      { x: 30, y: 0.03493 },
      { x: 31, y: 0.0352 },
      { x: 32, y: 0.03546 },
      { x: 33, y: 0.03571 },
      { x: 34, y: 0.03595 },
      { x: 35, y: 0.03617 },
      { x: 36, y: 0.03639 },
      { x: 37, y: 0.03659 },
      { x: 38, y: 0.03679 },
      { x: 39, y: 0.03698 },
      { x: 40, y: 0.03717 },
      { x: 41, y: 0.03734 },
      { x: 42, y: 0.03751 },
      { x: 43, y: 0.03767 },
      { x: 44, y: 0.03782 },
      { x: 45, y: 0.03797 },
      { x: 46, y: 0.03812 },
      { x: 47, y: 0.03826 },
      { x: 48, y: 0.03839 },
      { x: 49, y: 0.03852 },
      { x: 50, y: 0.03864 },
      { x: 51, y: 0.03876 },
      { x: 52, y: 0.03888 },
      { x: 53, y: 0.03899 },
      { x: 54, y: 0.03909 },
      { x: 55, y: 0.0392 },
      { x: 56, y: 0.0393 },
      { x: 57, y: 0.0394 },
      { x: 58, y: 0.03949 },
      { x: 59, y: 0.03958 },
      { x: 60, y: 0.03967 },
      { x: 61, y: 0.03976 },
      { x: 62, y: 0.03984 },
      { x: 63, y: 0.03992 },
      { x: 64, y: 0.04 },
      { x: 65, y: 0.04007 },
      { x: 66, y: 0.04015 },
      { x: 67, y: 0.04022 },
      { x: 68, y: 0.04029 },
      { x: 69, y: 0.04035 },
      { x: 70, y: 0.04042 },
      { x: 71, y: 0.04048 },
      { x: 72, y: 0.04055 },
      { x: 73, y: 0.04061 },
      { x: 74, y: 0.04066 },
      { x: 75, y: 0.04072 },
      { x: 76, y: 0.04078 },
      { x: 77, y: 0.04083 },
      { x: 78, y: 0.04089 },
      { x: 79, y: 0.04094 },
      { x: 80, y: 0.04099 },
      { x: 81, y: 0.04104 },
      { x: 82, y: 0.04109 },
      { x: 83, y: 0.04113 },
      { x: 84, y: 0.04118 },
      { x: 85, y: 0.04122 },
      { x: 86, y: 0.04127 },
      { x: 87, y: 0.04131 },
      { x: 88, y: 0.04135 },
      { x: 89, y: 0.04139 },
      { x: 90, y: 0.04143 },
      { x: 91, y: 0.04147 },
      { x: 92, y: 0.04151 },
      { x: 93, y: 0.04155 },
      { x: 94, y: 0.04158 },
      { x: 95, y: 0.04162 },
      { x: 96, y: 0.04165 },
      { x: 97, y: 0.04169 },
      { x: 98, y: 0.04172 },
      { x: 99, y: 0.04176 },
      { x: 100, y: 0.04179 },
      { x: 101, y: 0.04182 },
      { x: 102, y: 0.04185 },
      { x: 103, y: 0.04188 },
      { x: 104, y: 0.04191 },
      { x: 105, y: 0.04194 },
      { x: 106, y: 0.04197 },
      { x: 107, y: 0.042 },
      { x: 108, y: 0.04203 },
      { x: 109, y: 0.04205 },
      { x: 110, y: 0.04208 },
      { x: 111, y: 0.04211 },
      { x: 112, y: 0.04213 },
      { x: 113, y: 0.04216 },
      { x: 114, y: 0.04218 },
      { x: 115, y: 0.04221 },
      { x: 116, y: 0.04223 },
      { x: 117, y: 0.04225 },
      { x: 118, y: 0.04228 },
      { x: 119, y: 0.0423 },
      { x: 120, y: 0.04232 },
      { x: 121, y: 0.04234 },
      { x: 122, y: 0.04237 },
      { x: 123, y: 0.04239 },
      { x: 124, y: 0.04241 },
      { x: 125, y: 0.04243 },
      { x: 126, y: 0.04245 },
      { x: 127, y: 0.04247 },
      { x: 128, y: 0.04249 },
      { x: 129, y: 0.04251 },
      { x: 130, y: 0.04253 },
      { x: 131, y: 0.04255 },
      { x: 132, y: 0.04257 },
      { x: 133, y: 0.04258 },
      { x: 134, y: 0.0426 },
      { x: 135, y: 0.04262 },
      { x: 136, y: 0.04264 },
      { x: 137, y: 0.04265 },
      { x: 138, y: 0.04267 },
      { x: 139, y: 0.04269 },
      { x: 140, y: 0.0427 },
      { x: 141, y: 0.04272 },
      { x: 142, y: 0.04274 },
      { x: 143, y: 0.04275 },
      { x: 144, y: 0.04277 },
      { x: 145, y: 0.04278 },
      { x: 146, y: 0.0428 },
      { x: 147, y: 0.04281 },
      { x: 148, y: 0.04283 },
      { x: 149, y: 0.04284 },
      { x: 150, y: 0.04286 },
    ],
  },
  {
    id: "DE",
    date: new Date("2020-06-30T00:00:00.000Z"),
    data: [
      { x: 1, y: -0.00445 },
      { x: 2, y: -0.00475 },
      { x: 3, y: -0.00485 },
      { x: 4, y: -0.00465 },
      { x: 5, y: -0.00445 },
      { x: 6, y: -0.00415 },
      { x: 7, y: -0.00386 },
      { x: 8, y: -0.00346 },
      { x: 9, y: -0.00306 },
      { x: 10, y: -0.00267 },
      { x: 11, y: -0.00234 },
      { x: 12, y: -0.00187 },
      { x: 13, y: -0.00158 },
      { x: 14, y: -0.00125 },
      { x: 15, y: -0.00094 },
      { x: 16, y: -0.00082 },
      { x: 17, y: -0.0008 },
      { x: 18, y: -0.00075 },
      { x: 19, y: -0.00059 },
      { x: 20, y: -0.00027 },
      { x: 21, y: 0.00022 },
      { x: 22, y: 0.00086 },
      { x: 23, y: 0.00158 },
      { x: 24, y: 0.00236 },
      { x: 25, y: 0.00317 },
      { x: 26, y: 0.00399 },
      { x: 27, y: 0.00482 },
      { x: 28, y: 0.00564 },
      { x: 29, y: 0.00645 },
      { x: 30, y: 0.00724 },
      { x: 31, y: 0.00801 },
      { x: 32, y: 0.00876 },
      { x: 33, y: 0.00948 },
      { x: 34, y: 0.01018 },
      { x: 35, y: 0.01085 },
      { x: 36, y: 0.0115 },
      { x: 37, y: 0.01213 },
      { x: 38, y: 0.01273 },
      { x: 39, y: 0.0133 },
      { x: 40, y: 0.01386 },
      { x: 41, y: 0.01439 },
      { x: 42, y: 0.01491 },
      { x: 43, y: 0.0154 },
      { x: 44, y: 0.01588 },
      { x: 45, y: 0.01633 },
      { x: 46, y: 0.01677 },
      { x: 47, y: 0.01719 },
      { x: 48, y: 0.0176 },
      { x: 49, y: 0.01799 },
      { x: 50, y: 0.01837 },
      { x: 51, y: 0.01874 },
      { x: 52, y: 0.01909 },
      { x: 53, y: 0.01943 },
      { x: 54, y: 0.01975 },
      { x: 55, y: 0.02007 },
      { x: 56, y: 0.02038 },
      { x: 57, y: 0.02067 },
      { x: 58, y: 0.02096 },
      { x: 59, y: 0.02123 },
      { x: 60, y: 0.0215 },
      { x: 61, y: 0.02176 },
      { x: 62, y: 0.02201 },
      { x: 63, y: 0.02225 },
      { x: 64, y: 0.02249 },
      { x: 65, y: 0.02272 },
      { x: 66, y: 0.02294 },
      { x: 67, y: 0.02315 },
      { x: 68, y: 0.02336 },
      { x: 69, y: 0.02356 },
      { x: 70, y: 0.02376 },
      { x: 71, y: 0.02395 },
      { x: 72, y: 0.02414 },
      { x: 73, y: 0.02432 },
      { x: 74, y: 0.0245 },
      { x: 75, y: 0.02467 },
      { x: 76, y: 0.02484 },
      { x: 77, y: 0.025 },
      { x: 78, y: 0.02516 },
      { x: 79, y: 0.02532 },
      { x: 80, y: 0.02547 },
      { x: 81, y: 0.02561 },
      { x: 82, y: 0.02576 },
      { x: 83, y: 0.0259 },
      { x: 84, y: 0.02604 },
      { x: 85, y: 0.02617 },
      { x: 86, y: 0.0263 },
      { x: 87, y: 0.02643 },
      { x: 88, y: 0.02655 },
      { x: 89, y: 0.02668 },
      { x: 90, y: 0.0268 },
      { x: 91, y: 0.02691 },
      { x: 92, y: 0.02703 },
      { x: 93, y: 0.02714 },
      { x: 94, y: 0.02725 },
      { x: 95, y: 0.02736 },
      { x: 96, y: 0.02746 },
      { x: 97, y: 0.02757 },
      { x: 98, y: 0.02767 },
      { x: 99, y: 0.02777 },
      { x: 100, y: 0.02786 },
      { x: 101, y: 0.02796 },
      { x: 102, y: 0.02805 },
      { x: 103, y: 0.02814 },
      { x: 104, y: 0.02823 },
      { x: 105, y: 0.02832 },
      { x: 106, y: 0.02841 },
      { x: 107, y: 0.02849 },
      { x: 108, y: 0.02857 },
      { x: 109, y: 0.02865 },
      { x: 110, y: 0.02873 },
      { x: 111, y: 0.02881 },
      { x: 112, y: 0.02889 },
      { x: 113, y: 0.02897 },
      { x: 114, y: 0.02904 },
      { x: 115, y: 0.02911 },
      { x: 116, y: 0.02919 },
      { x: 117, y: 0.02926 },
      { x: 118, y: 0.02933 },
      { x: 119, y: 0.02939 },
      { x: 120, y: 0.02946 },
      { x: 121, y: 0.02953 },
      { x: 122, y: 0.02959 },
      { x: 123, y: 0.02966 },
      { x: 124, y: 0.02972 },
      { x: 125, y: 0.02978 },
      { x: 126, y: 0.02984 },
      { x: 127, y: 0.0299 },
      { x: 128, y: 0.02996 },
      { x: 129, y: 0.03002 },
      { x: 130, y: 0.03008 },
      { x: 131, y: 0.03013 },
      { x: 132, y: 0.03019 },
      { x: 133, y: 0.03024 },
      { x: 134, y: 0.0303 },
      { x: 135, y: 0.03035 },
      { x: 136, y: 0.0304 },
      { x: 137, y: 0.03046 },
      { x: 138, y: 0.03051 },
      { x: 139, y: 0.03056 },
      { x: 140, y: 0.03061 },
      { x: 141, y: 0.03066 },
      { x: 142, y: 0.0307 },
      { x: 143, y: 0.03075 },
      { x: 144, y: 0.0308 },
      { x: 145, y: 0.03084 },
      { x: 146, y: 0.03089 },
      { x: 147, y: 0.03093 },
      { x: 148, y: 0.03098 },
      { x: 149, y: 0.03102 },
      { x: 150, y: 0.03106 },
    ],
  },
  {
    id: "GB",
    date: new Date("2020-06-30T00:00:00.000Z"),
    data: [
      { x: 1, y: 0.00097 },
      { x: 2, y: 0.00047 },
      { x: 3, y: 0.00048 },
      { x: 4, y: 0.0007 },
      { x: 5, y: 0.001 },
      { x: 6, y: 0.00126 },
      { x: 7, y: 0.00153 },
      { x: 8, y: 0.0018 },
      { x: 9, y: 0.00206 },
      { x: 10, y: 0.0023 },
      { x: 11, y: 0.0025 },
      { x: 12, y: 0.00266 },
      { x: 13, y: 0.00279 },
      { x: 14, y: 0.0029 },
      { x: 15, y: 0.00298 },
      { x: 16, y: 0.00304 },
      { x: 17, y: 0.00308 },
      { x: 18, y: 0.00311 },
      { x: 19, y: 0.00313 },
      { x: 20, y: 0.00314 },
      { x: 21, y: 0.00314 },
      { x: 22, y: 0.00314 },
      { x: 23, y: 0.00313 },
      { x: 24, y: 0.00311 },
      { x: 25, y: 0.00308 },
      { x: 26, y: 0.00305 },
      { x: 27, y: 0.00301 },
      { x: 28, y: 0.00298 },
      { x: 29, y: 0.00296 },
      { x: 30, y: 0.00295 },
      { x: 31, y: 0.00294 },
      { x: 32, y: 0.00294 },
      { x: 33, y: 0.00294 },
      { x: 34, y: 0.00293 },
      { x: 35, y: 0.0029 },
      { x: 36, y: 0.00287 },
      { x: 37, y: 0.00281 },
      { x: 38, y: 0.00273 },
      { x: 39, y: 0.00263 },
      { x: 40, y: 0.0025 },
      { x: 41, y: 0.00234 },
      { x: 42, y: 0.00218 },
      { x: 43, y: 0.00203 },
      { x: 44, y: 0.0019 },
      { x: 45, y: 0.00182 },
      { x: 46, y: 0.00178 },
      { x: 47, y: 0.00179 },
      { x: 48, y: 0.00186 },
      { x: 49, y: 0.00198 },
      { x: 50, y: 0.00217 },
      { x: 51, y: 0.00242 },
      { x: 52, y: 0.00272 },
      { x: 53, y: 0.00306 },
      { x: 54, y: 0.00342 },
      { x: 55, y: 0.00381 },
      { x: 56, y: 0.00422 },
      { x: 57, y: 0.00463 },
      { x: 58, y: 0.00505 },
      { x: 59, y: 0.00548 },
      { x: 60, y: 0.0059 },
      { x: 61, y: 0.00633 },
      { x: 62, y: 0.00675 },
      { x: 63, y: 0.00717 },
      { x: 64, y: 0.00758 },
      { x: 65, y: 0.00799 },
      { x: 66, y: 0.00839 },
      { x: 67, y: 0.00878 },
      { x: 68, y: 0.00917 },
      { x: 69, y: 0.00955 },
      { x: 70, y: 0.00992 },
      { x: 71, y: 0.01028 },
      { x: 72, y: 0.01064 },
      { x: 73, y: 0.01099 },
      { x: 74, y: 0.01133 },
      { x: 75, y: 0.01166 },
      { x: 76, y: 0.01199 },
      { x: 77, y: 0.01231 },
      { x: 78, y: 0.01262 },
      { x: 79, y: 0.01293 },
      { x: 80, y: 0.01323 },
      { x: 81, y: 0.01352 },
      { x: 82, y: 0.0138 },
      { x: 83, y: 0.01408 },
      { x: 84, y: 0.01436 },
      { x: 85, y: 0.01462 },
      { x: 86, y: 0.01488 },
      { x: 87, y: 0.01514 },
      { x: 88, y: 0.01539 },
      { x: 89, y: 0.01563 },
      { x: 90, y: 0.01587 },
      { x: 91, y: 0.01611 },
      { x: 92, y: 0.01634 },
      { x: 93, y: 0.01656 },
      { x: 94, y: 0.01678 },
      { x: 95, y: 0.01699 },
      { x: 96, y: 0.01721 },
      { x: 97, y: 0.01741 },
      { x: 98, y: 0.01762 },
      { x: 99, y: 0.01781 },
      { x: 100, y: 0.01801 },
      { x: 101, y: 0.0182 },
      { x: 102, y: 0.01839 },
      { x: 103, y: 0.01857 },
      { x: 104, y: 0.01875 },
      { x: 105, y: 0.01893 },
      { x: 106, y: 0.0191 },
      { x: 107, y: 0.01927 },
      { x: 108, y: 0.01944 },
      { x: 109, y: 0.0196 },
      { x: 110, y: 0.01976 },
      { x: 111, y: 0.01992 },
      { x: 112, y: 0.02008 },
      { x: 113, y: 0.02023 },
      { x: 114, y: 0.02038 },
      { x: 115, y: 0.02053 },
      { x: 116, y: 0.02067 },
      { x: 117, y: 0.02082 },
      { x: 118, y: 0.02096 },
      { x: 119, y: 0.02109 },
      { x: 120, y: 0.02123 },
      { x: 121, y: 0.02136 },
      { x: 122, y: 0.02149 },
      { x: 123, y: 0.02162 },
      { x: 124, y: 0.02175 },
      { x: 125, y: 0.02188 },
      { x: 126, y: 0.022 },
      { x: 127, y: 0.02212 },
      { x: 128, y: 0.02224 },
      { x: 129, y: 0.02236 },
      { x: 130, y: 0.02247 },
      { x: 131, y: 0.02259 },
      { x: 132, y: 0.0227 },
      { x: 133, y: 0.02281 },
      { x: 134, y: 0.02292 },
      { x: 135, y: 0.02302 },
      { x: 136, y: 0.02313 },
      { x: 137, y: 0.02323 },
      { x: 138, y: 0.02334 },
      { x: 139, y: 0.02344 },
      { x: 140, y: 0.02354 },
      { x: 141, y: 0.02364 },
      { x: 142, y: 0.02373 },
      { x: 143, y: 0.02383 },
      { x: 144, y: 0.02392 },
      { x: 145, y: 0.02402 },
      { x: 146, y: 0.02411 },
      { x: 147, y: 0.0242 },
      { x: 148, y: 0.02429 },
      { x: 149, y: 0.02438 },
      { x: 150, y: 0.02446 },
    ],
  },
  {
    id: "HK",
    date: new Date("2020-06-30T00:00:00.000Z"),
    data: [
      { x: 1, y: 0.00411 },
      { x: 2, y: 0.0037 },
      { x: 3, y: 0.0037 },
      { x: 4, y: 0.00396 },
      { x: 5, y: 0.00441 },
      { x: 6, y: 0.00501 },
      { x: 7, y: 0.00561 },
      { x: 8, y: 0.00614 },
      { x: 9, y: 0.00657 },
      { x: 10, y: 0.00687 },
      { x: 11, y: 0.00705 },
      { x: 12, y: 0.00721 },
      { x: 13, y: 0.00741 },
      { x: 14, y: 0.00771 },
      { x: 15, y: 0.00812 },
      { x: 16, y: 0.00866 },
      { x: 17, y: 0.00928 },
      { x: 18, y: 0.00996 },
      { x: 19, y: 0.01067 },
      { x: 20, y: 0.01139 },
      { x: 21, y: 0.01211 },
      { x: 22, y: 0.01282 },
      { x: 23, y: 0.01352 },
      { x: 24, y: 0.0142 },
      { x: 25, y: 0.01486 },
      { x: 26, y: 0.0155 },
      { x: 27, y: 0.01612 },
      { x: 28, y: 0.01671 },
      { x: 29, y: 0.01729 },
      { x: 30, y: 0.01783 },
      { x: 31, y: 0.01836 },
      { x: 32, y: 0.01886 },
      { x: 33, y: 0.01935 },
      { x: 34, y: 0.01981 },
      { x: 35, y: 0.02026 },
      { x: 36, y: 0.02068 },
      { x: 37, y: 0.02109 },
      { x: 38, y: 0.02148 },
      { x: 39, y: 0.02186 },
      { x: 40, y: 0.02222 },
      { x: 41, y: 0.02257 },
      { x: 42, y: 0.0229 },
      { x: 43, y: 0.02322 },
      { x: 44, y: 0.02352 },
      { x: 45, y: 0.02382 },
      { x: 46, y: 0.0241 },
      { x: 47, y: 0.02437 },
      { x: 48, y: 0.02464 },
      { x: 49, y: 0.02489 },
      { x: 50, y: 0.02513 },
      { x: 51, y: 0.02537 },
      { x: 52, y: 0.0256 },
      { x: 53, y: 0.02581 },
      { x: 54, y: 0.02603 },
      { x: 55, y: 0.02623 },
      { x: 56, y: 0.02643 },
      { x: 57, y: 0.02662 },
      { x: 58, y: 0.0268 },
      { x: 59, y: 0.02698 },
      { x: 60, y: 0.02715 },
      { x: 61, y: 0.02732 },
      { x: 62, y: 0.02748 },
      { x: 63, y: 0.02764 },
      { x: 64, y: 0.02779 },
      { x: 65, y: 0.02794 },
      { x: 66, y: 0.02808 },
      { x: 67, y: 0.02822 },
      { x: 68, y: 0.02836 },
      { x: 69, y: 0.02849 },
      { x: 70, y: 0.02862 },
      { x: 71, y: 0.02874 },
      { x: 72, y: 0.02886 },
      { x: 73, y: 0.02898 },
      { x: 74, y: 0.02909 },
      { x: 75, y: 0.0292 },
      { x: 76, y: 0.02931 },
      { x: 77, y: 0.02942 },
      { x: 78, y: 0.02952 },
      { x: 79, y: 0.02962 },
      { x: 80, y: 0.02972 },
      { x: 81, y: 0.02982 },
      { x: 82, y: 0.02991 },
      { x: 83, y: 0.03 },
      { x: 84, y: 0.03009 },
      { x: 85, y: 0.03018 },
      { x: 86, y: 0.03026 },
      { x: 87, y: 0.03034 },
      { x: 88, y: 0.03042 },
      { x: 89, y: 0.0305 },
      { x: 90, y: 0.03058 },
      { x: 91, y: 0.03066 },
      { x: 92, y: 0.03073 },
      { x: 93, y: 0.0308 },
      { x: 94, y: 0.03087 },
      { x: 95, y: 0.03094 },
      { x: 96, y: 0.03101 },
      { x: 97, y: 0.03108 },
      { x: 98, y: 0.03114 },
      { x: 99, y: 0.03121 },
      { x: 100, y: 0.03127 },
      { x: 101, y: 0.03133 },
      { x: 102, y: 0.03139 },
      { x: 103, y: 0.03145 },
      { x: 104, y: 0.03151 },
      { x: 105, y: 0.03157 },
      { x: 106, y: 0.03162 },
      { x: 107, y: 0.03168 },
      { x: 108, y: 0.03173 },
      { x: 109, y: 0.03178 },
      { x: 110, y: 0.03183 },
      { x: 111, y: 0.03189 },
      { x: 112, y: 0.03194 },
      { x: 113, y: 0.03198 },
      { x: 114, y: 0.03203 },
      { x: 115, y: 0.03208 },
      { x: 116, y: 0.03213 },
      { x: 117, y: 0.03217 },
      { x: 118, y: 0.03222 },
      { x: 119, y: 0.03226 },
      { x: 120, y: 0.03231 },
      { x: 121, y: 0.03235 },
      { x: 122, y: 0.03239 },
      { x: 123, y: 0.03243 },
      { x: 124, y: 0.03247 },
      { x: 125, y: 0.03251 },
      { x: 126, y: 0.03255 },
      { x: 127, y: 0.03259 },
      { x: 128, y: 0.03263 },
      { x: 129, y: 0.03267 },
      { x: 130, y: 0.0327 },
      { x: 131, y: 0.03274 },
      { x: 132, y: 0.03278 },
      { x: 133, y: 0.03281 },
      { x: 134, y: 0.03285 },
      { x: 135, y: 0.03288 },
      { x: 136, y: 0.03292 },
      { x: 137, y: 0.03295 },
      { x: 138, y: 0.03298 },
      { x: 139, y: 0.03301 },
      { x: 140, y: 0.03305 },
      { x: 141, y: 0.03308 },
      { x: 142, y: 0.03311 },
      { x: 143, y: 0.03314 },
      { x: 144, y: 0.03317 },
      { x: 145, y: 0.0332 },
      { x: 146, y: 0.03323 },
      { x: 147, y: 0.03326 },
      { x: 148, y: 0.03329 },
      { x: 149, y: 0.03331 },
      { x: 150, y: 0.03334 },
    ],
  },
  {
    id: "JP",
    date: new Date("2020-06-30T00:00:00.000Z"),
    data: [
      { x: 1, y: -0.00116 },
      { x: 2, y: -0.00122 },
      { x: 3, y: -0.00127 },
      { x: 4, y: -0.00125 },
      { x: 5, y: -0.00119 },
      { x: 6, y: -0.00108 },
      { x: 7, y: -0.00093 },
      { x: 8, y: -0.00074 },
      { x: 9, y: -0.00053 },
      { x: 10, y: -0.0003 },
      { x: 11, y: -0.00007 },
      { x: 12, y: 0.00015 },
      { x: 13, y: 0.00037 },
      { x: 14, y: 0.00059 },
      { x: 15, y: 0.00083 },
      { x: 16, y: 0.00108 },
      { x: 17, y: 0.00131 },
      { x: 18, y: 0.0015 },
      { x: 19, y: 0.00163 },
      { x: 20, y: 0.00168 },
      { x: 21, y: 0.00167 },
      { x: 22, y: 0.00162 },
      { x: 23, y: 0.00157 },
      { x: 24, y: 0.00155 },
      { x: 25, y: 0.00156 },
      { x: 26, y: 0.00163 },
      { x: 27, y: 0.00176 },
      { x: 28, y: 0.00195 },
      { x: 29, y: 0.00221 },
      { x: 30, y: 0.00255 },
      { x: 31, y: 0.00296 },
      { x: 32, y: 0.00343 },
      { x: 33, y: 0.00394 },
      { x: 34, y: 0.00447 },
      { x: 35, y: 0.00503 },
      { x: 36, y: 0.00558 },
      { x: 37, y: 0.00615 },
      { x: 38, y: 0.00671 },
      { x: 39, y: 0.00726 },
      { x: 40, y: 0.00781 },
      { x: 41, y: 0.00835 },
      { x: 42, y: 0.00888 },
      { x: 43, y: 0.00939 },
      { x: 44, y: 0.00989 },
      { x: 45, y: 0.01038 },
      { x: 46, y: 0.01086 },
      { x: 47, y: 0.01132 },
      { x: 48, y: 0.01177 },
      { x: 49, y: 0.0122 },
      { x: 50, y: 0.01263 },
      { x: 51, y: 0.01303 },
      { x: 52, y: 0.01343 },
      { x: 53, y: 0.01382 },
      { x: 54, y: 0.01419 },
      { x: 55, y: 0.01455 },
      { x: 56, y: 0.0149 },
      { x: 57, y: 0.01524 },
      { x: 58, y: 0.01557 },
      { x: 59, y: 0.01588 },
      { x: 60, y: 0.01619 },
      { x: 61, y: 0.01649 },
      { x: 62, y: 0.01678 },
      { x: 63, y: 0.01707 },
      { x: 64, y: 0.01734 },
      { x: 65, y: 0.01761 },
      { x: 66, y: 0.01787 },
      { x: 67, y: 0.01812 },
      { x: 68, y: 0.01836 },
      { x: 69, y: 0.0186 },
      { x: 70, y: 0.01883 },
      { x: 71, y: 0.01905 },
      { x: 72, y: 0.01927 },
      { x: 73, y: 0.01949 },
      { x: 74, y: 0.01969 },
      { x: 75, y: 0.01989 },
      { x: 76, y: 0.02009 },
      { x: 77, y: 0.02028 },
      { x: 78, y: 0.02047 },
      { x: 79, y: 0.02065 },
      { x: 80, y: 0.02083 },
      { x: 81, y: 0.021 },
      { x: 82, y: 0.02117 },
      { x: 83, y: 0.02134 },
      { x: 84, y: 0.0215 },
      { x: 85, y: 0.02166 },
      { x: 86, y: 0.02181 },
      { x: 87, y: 0.02196 },
      { x: 88, y: 0.02211 },
      { x: 89, y: 0.02225 },
      { x: 90, y: 0.02239 },
      { x: 91, y: 0.02253 },
      { x: 92, y: 0.02266 },
      { x: 93, y: 0.0228 },
      { x: 94, y: 0.02293 },
      { x: 95, y: 0.02305 },
      { x: 96, y: 0.02318 },
      { x: 97, y: 0.0233 },
      { x: 98, y: 0.02342 },
      { x: 99, y: 0.02353 },
      { x: 100, y: 0.02365 },
      { x: 101, y: 0.02376 },
      { x: 102, y: 0.02387 },
      { x: 103, y: 0.02397 },
      { x: 104, y: 0.02408 },
      { x: 105, y: 0.02418 },
      { x: 106, y: 0.02429 },
      { x: 107, y: 0.02438 },
      { x: 108, y: 0.02448 },
      { x: 109, y: 0.02458 },
      { x: 110, y: 0.02467 },
      { x: 111, y: 0.02477 },
      { x: 112, y: 0.02486 },
      { x: 113, y: 0.02495 },
      { x: 114, y: 0.02503 },
      { x: 115, y: 0.02512 },
      { x: 116, y: 0.0252 },
      { x: 117, y: 0.02529 },
      { x: 118, y: 0.02537 },
      { x: 119, y: 0.02545 },
      { x: 120, y: 0.02553 },
      { x: 121, y: 0.02561 },
      { x: 122, y: 0.02568 },
      { x: 123, y: 0.02576 },
      { x: 124, y: 0.02583 },
      { x: 125, y: 0.02591 },
      { x: 126, y: 0.02598 },
      { x: 127, y: 0.02605 },
      { x: 128, y: 0.02612 },
      { x: 129, y: 0.02619 },
      { x: 130, y: 0.02625 },
      { x: 131, y: 0.02632 },
      { x: 132, y: 0.02639 },
      { x: 133, y: 0.02645 },
      { x: 134, y: 0.02651 },
      { x: 135, y: 0.02658 },
      { x: 136, y: 0.02664 },
      { x: 137, y: 0.0267 },
      { x: 138, y: 0.02676 },
      { x: 139, y: 0.02682 },
      { x: 140, y: 0.02688 },
      { x: 141, y: 0.02693 },
      { x: 142, y: 0.02699 },
      { x: 143, y: 0.02705 },
      { x: 144, y: 0.0271 },
      { x: 145, y: 0.02716 },
      { x: 146, y: 0.02721 },
      { x: 147, y: 0.02726 },
      { x: 148, y: 0.02731 },
      { x: 149, y: 0.02737 },
      { x: 150, y: 0.02742 },
    ],
  },
  {
    id: "NO",
    date: new Date("2020-06-30T00:00:00.000Z"),
    data: [
      { x: 1, y: 0.00252 },
      { x: 2, y: 0.00365 },
      { x: 3, y: 0.00415 },
      { x: 4, y: 0.00486 },
      { x: 5, y: 0.00549 },
      { x: 6, y: 0.00594 },
      { x: 7, y: 0.00633 },
      { x: 8, y: 0.00678 },
      { x: 9, y: 0.00731 },
      { x: 10, y: 0.00797 },
      { x: 11, y: 0.00875 },
      { x: 12, y: 0.00959 },
      { x: 13, y: 0.01046 },
      { x: 14, y: 0.01135 },
      { x: 15, y: 0.01221 },
      { x: 16, y: 0.01306 },
      { x: 17, y: 0.01389 },
      { x: 18, y: 0.01468 },
      { x: 19, y: 0.01544 },
      { x: 20, y: 0.01617 },
      { x: 21, y: 0.01686 },
      { x: 22, y: 0.01753 },
      { x: 23, y: 0.01816 },
      { x: 24, y: 0.01876 },
      { x: 25, y: 0.01933 },
      { x: 26, y: 0.01988 },
      { x: 27, y: 0.0204 },
      { x: 28, y: 0.0209 },
      { x: 29, y: 0.02137 },
      { x: 30, y: 0.02182 },
      { x: 31, y: 0.02225 },
      { x: 32, y: 0.02266 },
      { x: 33, y: 0.02305 },
      { x: 34, y: 0.02343 },
      { x: 35, y: 0.02379 },
      { x: 36, y: 0.02413 },
      { x: 37, y: 0.02445 },
      { x: 38, y: 0.02477 },
      { x: 39, y: 0.02507 },
      { x: 40, y: 0.02535 },
      { x: 41, y: 0.02563 },
      { x: 42, y: 0.02589 },
      { x: 43, y: 0.02615 },
      { x: 44, y: 0.02639 },
      { x: 45, y: 0.02663 },
      { x: 46, y: 0.02685 },
      { x: 47, y: 0.02707 },
      { x: 48, y: 0.02728 },
      { x: 49, y: 0.02748 },
      { x: 50, y: 0.02767 },
      { x: 51, y: 0.02786 },
      { x: 52, y: 0.02804 },
      { x: 53, y: 0.02821 },
      { x: 54, y: 0.02838 },
      { x: 55, y: 0.02854 },
      { x: 56, y: 0.02869 },
      { x: 57, y: 0.02885 },
      { x: 58, y: 0.02899 },
      { x: 59, y: 0.02913 },
      { x: 60, y: 0.02927 },
      { x: 61, y: 0.0294 },
      { x: 62, y: 0.02953 },
      { x: 63, y: 0.02966 },
      { x: 64, y: 0.02978 },
      { x: 65, y: 0.0299 },
      { x: 66, y: 0.03001 },
      { x: 67, y: 0.03012 },
      { x: 68, y: 0.03023 },
      { x: 69, y: 0.03033 },
      { x: 70, y: 0.03043 },
      { x: 71, y: 0.03053 },
      { x: 72, y: 0.03063 },
      { x: 73, y: 0.03072 },
      { x: 74, y: 0.03081 },
      { x: 75, y: 0.0309 },
      { x: 76, y: 0.03099 },
      { x: 77, y: 0.03107 },
      { x: 78, y: 0.03115 },
      { x: 79, y: 0.03123 },
      { x: 80, y: 0.03131 },
      { x: 81, y: 0.03139 },
      { x: 82, y: 0.03146 },
      { x: 83, y: 0.03153 },
      { x: 84, y: 0.0316 },
      { x: 85, y: 0.03167 },
      { x: 86, y: 0.03174 },
      { x: 87, y: 0.03181 },
      { x: 88, y: 0.03187 },
      { x: 89, y: 0.03193 },
      { x: 90, y: 0.032 },
      { x: 91, y: 0.03206 },
      { x: 92, y: 0.03212 },
      { x: 93, y: 0.03217 },
      { x: 94, y: 0.03223 },
      { x: 95, y: 0.03229 },
      { x: 96, y: 0.03234 },
      { x: 97, y: 0.03239 },
      { x: 98, y: 0.03244 },
      { x: 99, y: 0.0325 },
      { x: 100, y: 0.03255 },
      { x: 101, y: 0.03259 },
      { x: 102, y: 0.03264 },
      { x: 103, y: 0.03269 },
      { x: 104, y: 0.03274 },
      { x: 105, y: 0.03278 },
      { x: 106, y: 0.03282 },
      { x: 107, y: 0.03287 },
      { x: 108, y: 0.03291 },
      { x: 109, y: 0.03295 },
      { x: 110, y: 0.03299 },
      { x: 111, y: 0.03303 },
      { x: 112, y: 0.03307 },
      { x: 113, y: 0.03311 },
      { x: 114, y: 0.03315 },
      { x: 115, y: 0.03319 },
      { x: 116, y: 0.03323 },
      { x: 117, y: 0.03326 },
      { x: 118, y: 0.0333 },
      { x: 119, y: 0.03333 },
      { x: 120, y: 0.03337 },
      { x: 121, y: 0.0334 },
      { x: 122, y: 0.03344 },
      { x: 123, y: 0.03347 },
      { x: 124, y: 0.0335 },
      { x: 125, y: 0.03353 },
      { x: 126, y: 0.03357 },
      { x: 127, y: 0.0336 },
      { x: 128, y: 0.03363 },
      { x: 129, y: 0.03366 },
      { x: 130, y: 0.03369 },
      { x: 131, y: 0.03372 },
      { x: 132, y: 0.03374 },
      { x: 133, y: 0.03377 },
      { x: 134, y: 0.0338 },
      { x: 135, y: 0.03383 },
      { x: 136, y: 0.03385 },
      { x: 137, y: 0.03388 },
      { x: 138, y: 0.03391 },
      { x: 139, y: 0.03393 },
      { x: 140, y: 0.03396 },
      { x: 141, y: 0.03398 },
      { x: 142, y: 0.03401 },
      { x: 143, y: 0.03403 },
      { x: 144, y: 0.03406 },
      { x: 145, y: 0.03408 },
      { x: 146, y: 0.0341 },
      { x: 147, y: 0.03413 },
      { x: 148, y: 0.03415 },
      { x: 149, y: 0.03417 },
      { x: 150, y: 0.03419 },
    ],
  },
  {
    id: "RU",
    date: new Date("2020-06-30T00:00:00.000Z"),
    data: [
      { x: 1, y: 0.03942 },
      { x: 2, y: 0.04203 },
      { x: 3, y: 0.04464 },
      { x: 4, y: 0.04701 },
      { x: 5, y: 0.04934 },
      { x: 6, y: 0.05138 },
      { x: 7, y: 0.05318 },
      { x: 8, y: 0.05477 },
      { x: 9, y: 0.05616 },
      { x: 10, y: 0.05737 },
      { x: 11, y: 0.05844 },
      { x: 12, y: 0.05939 },
      { x: 13, y: 0.06019 },
      { x: 14, y: 0.06083 },
      { x: 15, y: 0.06129 },
      { x: 16, y: 0.06158 },
      { x: 17, y: 0.06173 },
      { x: 18, y: 0.06176 },
      { x: 19, y: 0.0617 },
      { x: 20, y: 0.06157 },
      { x: 21, y: 0.06139 },
      { x: 22, y: 0.06118 },
      { x: 23, y: 0.06094 },
      { x: 24, y: 0.06067 },
      { x: 25, y: 0.06039 },
      { x: 26, y: 0.06011 },
      { x: 27, y: 0.05981 },
      { x: 28, y: 0.05952 },
      { x: 29, y: 0.05923 },
      { x: 30, y: 0.05894 },
      { x: 31, y: 0.05865 },
      { x: 32, y: 0.05837 },
      { x: 33, y: 0.0581 },
      { x: 34, y: 0.05783 },
      { x: 35, y: 0.05757 },
      { x: 36, y: 0.05732 },
      { x: 37, y: 0.05707 },
      { x: 38, y: 0.05683 },
      { x: 39, y: 0.0566 },
      { x: 40, y: 0.05638 },
      { x: 41, y: 0.05616 },
      { x: 42, y: 0.05596 },
      { x: 43, y: 0.05576 },
      { x: 44, y: 0.05556 },
      { x: 45, y: 0.05538 },
      { x: 46, y: 0.05519 },
      { x: 47, y: 0.05502 },
      { x: 48, y: 0.05485 },
      { x: 49, y: 0.05469 },
      { x: 50, y: 0.05453 },
      { x: 51, y: 0.05438 },
      { x: 52, y: 0.05423 },
      { x: 53, y: 0.05409 },
      { x: 54, y: 0.05396 },
      { x: 55, y: 0.05382 },
      { x: 56, y: 0.0537 },
      { x: 57, y: 0.05357 },
      { x: 58, y: 0.05345 },
      { x: 59, y: 0.05334 },
      { x: 60, y: 0.05322 },
      { x: 61, y: 0.05311 },
      { x: 62, y: 0.05301 },
      { x: 63, y: 0.05291 },
      { x: 64, y: 0.05281 },
      { x: 65, y: 0.05271 },
      { x: 66, y: 0.05262 },
      { x: 67, y: 0.05253 },
      { x: 68, y: 0.05244 },
      { x: 69, y: 0.05235 },
      { x: 70, y: 0.05227 },
      { x: 71, y: 0.05219 },
      { x: 72, y: 0.05211 },
      { x: 73, y: 0.05203 },
      { x: 74, y: 0.05196 },
      { x: 75, y: 0.05189 },
      { x: 76, y: 0.05181 },
      { x: 77, y: 0.05175 },
      { x: 78, y: 0.05168 },
      { x: 79, y: 0.05161 },
      { x: 80, y: 0.05155 },
      { x: 81, y: 0.05149 },
      { x: 82, y: 0.05143 },
      { x: 83, y: 0.05137 },
      { x: 84, y: 0.05131 },
      { x: 85, y: 0.05125 },
      { x: 86, y: 0.0512 },
      { x: 87, y: 0.05114 },
      { x: 88, y: 0.05109 },
      { x: 89, y: 0.05104 },
      { x: 90, y: 0.05099 },
      { x: 91, y: 0.05094 },
      { x: 92, y: 0.05089 },
      { x: 93, y: 0.05084 },
      { x: 94, y: 0.0508 },
      { x: 95, y: 0.05075 },
      { x: 96, y: 0.05071 },
      { x: 97, y: 0.05066 },
      { x: 98, y: 0.05062 },
      { x: 99, y: 0.05058 },
      { x: 100, y: 0.05054 },
      { x: 101, y: 0.0505 },
      { x: 102, y: 0.05046 },
      { x: 103, y: 0.05042 },
      { x: 104, y: 0.05038 },
      { x: 105, y: 0.05035 },
      { x: 106, y: 0.05031 },
      { x: 107, y: 0.05027 },
      { x: 108, y: 0.05024 },
      { x: 109, y: 0.0502 },
      { x: 110, y: 0.05017 },
      { x: 111, y: 0.05014 },
      { x: 112, y: 0.0501 },
      { x: 113, y: 0.05007 },
      { x: 114, y: 0.05004 },
      { x: 115, y: 0.05001 },
      { x: 116, y: 0.04998 },
      { x: 117, y: 0.04995 },
      { x: 118, y: 0.04992 },
      { x: 119, y: 0.04989 },
      { x: 120, y: 0.04986 },
      { x: 121, y: 0.04984 },
      { x: 122, y: 0.04981 },
      { x: 123, y: 0.04978 },
      { x: 124, y: 0.04975 },
      { x: 125, y: 0.04973 },
      { x: 126, y: 0.0497 },
      { x: 127, y: 0.04968 },
      { x: 128, y: 0.04965 },
      { x: 129, y: 0.04963 },
      { x: 130, y: 0.0496 },
      { x: 131, y: 0.04958 },
      { x: 132, y: 0.04956 },
      { x: 133, y: 0.04953 },
      { x: 134, y: 0.04951 },
      { x: 135, y: 0.04949 },
      { x: 136, y: 0.04947 },
      { x: 137, y: 0.04945 },
      { x: 138, y: 0.04942 },
      { x: 139, y: 0.0494 },
      { x: 140, y: 0.04938 },
      { x: 141, y: 0.04936 },
      { x: 142, y: 0.04934 },
      { x: 143, y: 0.04932 },
      { x: 144, y: 0.0493 },
      { x: 145, y: 0.04928 },
      { x: 146, y: 0.04926 },
      { x: 147, y: 0.04925 },
      { x: 148, y: 0.04923 },
      { x: 149, y: 0.04921 },
      { x: 150, y: 0.04919 },
    ],
  },
  {
    id: "SG",
    date: new Date("2020-06-30T00:00:00.000Z"),
    data: [
      { x: 1, y: -0.00025 },
      { x: 2, y: 0.00025 },
      { x: 3, y: 0.00088 },
      { x: 4, y: 0.00163 },
      { x: 5, y: 0.00248 },
      { x: 6, y: 0.00339 },
      { x: 7, y: 0.00422 },
      { x: 8, y: 0.00489 },
      { x: 9, y: 0.00537 },
      { x: 10, y: 0.00564 },
      { x: 11, y: 0.00571 },
      { x: 12, y: 0.00569 },
      { x: 13, y: 0.00566 },
      { x: 14, y: 0.00564 },
      { x: 15, y: 0.00568 },
      { x: 16, y: 0.0058 },
      { x: 17, y: 0.00599 },
      { x: 18, y: 0.00627 },
      { x: 19, y: 0.00664 },
      { x: 20, y: 0.0071 },
      { x: 21, y: 0.00766 },
      { x: 22, y: 0.00827 },
      { x: 23, y: 0.00893 },
      { x: 24, y: 0.0096 },
      { x: 25, y: 0.01029 },
      { x: 26, y: 0.01097 },
      { x: 27, y: 0.01165 },
      { x: 28, y: 0.01231 },
      { x: 29, y: 0.01296 },
      { x: 30, y: 0.01359 },
      { x: 31, y: 0.0142 },
      { x: 32, y: 0.0148 },
      { x: 33, y: 0.01537 },
      { x: 34, y: 0.01592 },
      { x: 35, y: 0.01645 },
      { x: 36, y: 0.01697 },
      { x: 37, y: 0.01746 },
      { x: 38, y: 0.01793 },
      { x: 39, y: 0.01839 },
      { x: 40, y: 0.01882 },
      { x: 41, y: 0.01925 },
      { x: 42, y: 0.01965 },
      { x: 43, y: 0.02004 },
      { x: 44, y: 0.02041 },
      { x: 45, y: 0.02077 },
      { x: 46, y: 0.02112 },
      { x: 47, y: 0.02145 },
      { x: 48, y: 0.02177 },
      { x: 49, y: 0.02208 },
      { x: 50, y: 0.02238 },
      { x: 51, y: 0.02267 },
      { x: 52, y: 0.02295 },
      { x: 53, y: 0.02321 },
      { x: 54, y: 0.02347 },
      { x: 55, y: 0.02372 },
      { x: 56, y: 0.02396 },
      { x: 57, y: 0.0242 },
      { x: 58, y: 0.02442 },
      { x: 59, y: 0.02464 },
      { x: 60, y: 0.02485 },
      { x: 61, y: 0.02506 },
      { x: 62, y: 0.02525 },
      { x: 63, y: 0.02545 },
      { x: 64, y: 0.02563 },
      { x: 65, y: 0.02581 },
      { x: 66, y: 0.02599 },
      { x: 67, y: 0.02616 },
      { x: 68, y: 0.02632 },
      { x: 69, y: 0.02648 },
      { x: 70, y: 0.02664 },
      { x: 71, y: 0.02679 },
      { x: 72, y: 0.02694 },
      { x: 73, y: 0.02708 },
      { x: 74, y: 0.02722 },
      { x: 75, y: 0.02736 },
      { x: 76, y: 0.02749 },
      { x: 77, y: 0.02762 },
      { x: 78, y: 0.02775 },
      { x: 79, y: 0.02787 },
      { x: 80, y: 0.02799 },
      { x: 81, y: 0.02811 },
      { x: 82, y: 0.02822 },
      { x: 83, y: 0.02833 },
      { x: 84, y: 0.02844 },
      { x: 85, y: 0.02855 },
      { x: 86, y: 0.02865 },
      { x: 87, y: 0.02875 },
      { x: 88, y: 0.02885 },
      { x: 89, y: 0.02895 },
      { x: 90, y: 0.02904 },
      { x: 91, y: 0.02913 },
      { x: 92, y: 0.02922 },
      { x: 93, y: 0.02931 },
      { x: 94, y: 0.0294 },
      { x: 95, y: 0.02948 },
      { x: 96, y: 0.02957 },
      { x: 97, y: 0.02965 },
      { x: 98, y: 0.02973 },
      { x: 99, y: 0.02981 },
      { x: 100, y: 0.02988 },
      { x: 101, y: 0.02996 },
      { x: 102, y: 0.03003 },
      { x: 103, y: 0.03011 },
      { x: 104, y: 0.03018 },
      { x: 105, y: 0.03025 },
      { x: 106, y: 0.03031 },
      { x: 107, y: 0.03038 },
      { x: 108, y: 0.03045 },
      { x: 109, y: 0.03051 },
      { x: 110, y: 0.03057 },
      { x: 111, y: 0.03064 },
      { x: 112, y: 0.0307 },
      { x: 113, y: 0.03076 },
      { x: 114, y: 0.03082 },
      { x: 115, y: 0.03087 },
      { x: 116, y: 0.03093 },
      { x: 117, y: 0.03099 },
      { x: 118, y: 0.03104 },
      { x: 119, y: 0.0311 },
      { x: 120, y: 0.03115 },
      { x: 121, y: 0.0312 },
      { x: 122, y: 0.03125 },
      { x: 123, y: 0.0313 },
      { x: 124, y: 0.03135 },
      { x: 125, y: 0.0314 },
      { x: 126, y: 0.03145 },
      { x: 127, y: 0.0315 },
      { x: 128, y: 0.03155 },
      { x: 129, y: 0.03159 },
      { x: 130, y: 0.03164 },
      { x: 131, y: 0.03168 },
      { x: 132, y: 0.03173 },
      { x: 133, y: 0.03177 },
      { x: 134, y: 0.03181 },
      { x: 135, y: 0.03185 },
      { x: 136, y: 0.03189 },
      { x: 137, y: 0.03194 },
      { x: 138, y: 0.03198 },
      { x: 139, y: 0.03202 },
      { x: 140, y: 0.03205 },
      { x: 141, y: 0.03209 },
      { x: 142, y: 0.03213 },
      { x: 143, y: 0.03217 },
      { x: 144, y: 0.03221 },
      { x: 145, y: 0.03224 },
      { x: 146, y: 0.03228 },
      { x: 147, y: 0.03231 },
      { x: 148, y: 0.03235 },
      { x: 149, y: 0.03238 },
      { x: 150, y: 0.03242 },
    ],
  },
  {
    id: "US",
    date: new Date("2020-06-30T00:00:00.000Z"),
    data: [
      { x: 1, y: 0.00062 },
      { x: 2, y: 0.00024 },
      { x: 3, y: 0.00032 },
      { x: 4, y: 0.00067 },
      { x: 5, y: 0.00126 },
      { x: 6, y: 0.00196 },
      { x: 7, y: 0.00262 },
      { x: 8, y: 0.00332 },
      { x: 9, y: 0.00391 },
      { x: 10, y: 0.00443 },
      { x: 11, y: 0.00489 },
      { x: 12, y: 0.00528 },
      { x: 13, y: 0.0056 },
      { x: 14, y: 0.00588 },
      { x: 15, y: 0.00612 },
      { x: 16, y: 0.00635 },
      { x: 17, y: 0.00656 },
      { x: 18, y: 0.00674 },
      { x: 19, y: 0.00688 },
      { x: 20, y: 0.00697 },
      { x: 21, y: 0.00703 },
      { x: 22, y: 0.00707 },
      { x: 23, y: 0.0071 },
      { x: 24, y: 0.00715 },
      { x: 25, y: 0.00723 },
      { x: 26, y: 0.00732 },
      { x: 27, y: 0.0074 },
      { x: 28, y: 0.00745 },
      { x: 29, y: 0.00743 },
      { x: 30, y: 0.00732 },
      { x: 31, y: 0.00713 },
      { x: 32, y: 0.00689 },
      { x: 33, y: 0.00661 },
      { x: 34, y: 0.00633 },
      { x: 35, y: 0.00607 },
      { x: 36, y: 0.00582 },
      { x: 37, y: 0.00561 },
      { x: 38, y: 0.00543 },
      { x: 39, y: 0.00528 },
      { x: 40, y: 0.00517 },
      { x: 41, y: 0.00511 },
      { x: 42, y: 0.00508 },
      { x: 43, y: 0.00509 },
      { x: 44, y: 0.00513 },
      { x: 45, y: 0.00522 },
      { x: 46, y: 0.00534 },
      { x: 47, y: 0.00549 },
      { x: 48, y: 0.00568 },
      { x: 49, y: 0.0059 },
      { x: 50, y: 0.00616 },
      { x: 51, y: 0.00646 },
      { x: 52, y: 0.00679 },
      { x: 53, y: 0.00713 },
      { x: 54, y: 0.00749 },
      { x: 55, y: 0.00786 },
      { x: 56, y: 0.00824 },
      { x: 57, y: 0.00863 },
      { x: 58, y: 0.00901 },
      { x: 59, y: 0.0094 },
      { x: 60, y: 0.00978 },
      { x: 61, y: 0.01016 },
      { x: 62, y: 0.01053 },
      { x: 63, y: 0.01091 },
      { x: 64, y: 0.01127 },
      { x: 65, y: 0.01163 },
      { x: 66, y: 0.01199 },
      { x: 67, y: 0.01233 },
      { x: 68, y: 0.01267 },
      { x: 69, y: 0.01301 },
      { x: 70, y: 0.01333 },
      { x: 71, y: 0.01365 },
      { x: 72, y: 0.01397 },
      { x: 73, y: 0.01427 },
      { x: 74, y: 0.01457 },
      { x: 75, y: 0.01487 },
      { x: 76, y: 0.01515 },
      { x: 77, y: 0.01543 },
      { x: 78, y: 0.01571 },
      { x: 79, y: 0.01597 },
      { x: 80, y: 0.01624 },
      { x: 81, y: 0.01649 },
      { x: 82, y: 0.01674 },
      { x: 83, y: 0.01699 },
      { x: 84, y: 0.01722 },
      { x: 85, y: 0.01746 },
      { x: 86, y: 0.01769 },
      { x: 87, y: 0.01791 },
      { x: 88, y: 0.01813 },
      { x: 89, y: 0.01834 },
      { x: 90, y: 0.01855 },
      { x: 91, y: 0.01876 },
      { x: 92, y: 0.01896 },
      { x: 93, y: 0.01916 },
      { x: 94, y: 0.01935 },
      { x: 95, y: 0.01954 },
      { x: 96, y: 0.01972 },
      { x: 97, y: 0.0199 },
      { x: 98, y: 0.02008 },
      { x: 99, y: 0.02026 },
      { x: 100, y: 0.02043 },
      { x: 101, y: 0.02059 },
      { x: 102, y: 0.02076 },
      { x: 103, y: 0.02092 },
      { x: 104, y: 0.02108 },
      { x: 105, y: 0.02123 },
      { x: 106, y: 0.02138 },
      { x: 107, y: 0.02153 },
      { x: 108, y: 0.02168 },
      { x: 109, y: 0.02182 },
      { x: 110, y: 0.02197 },
      { x: 111, y: 0.0221 },
      { x: 112, y: 0.02224 },
      { x: 113, y: 0.02237 },
      { x: 114, y: 0.02251 },
      { x: 115, y: 0.02264 },
      { x: 116, y: 0.02276 },
      { x: 117, y: 0.02289 },
      { x: 118, y: 0.02301 },
      { x: 119, y: 0.02313 },
      { x: 120, y: 0.02325 },
      { x: 121, y: 0.02337 },
      { x: 122, y: 0.02348 },
      { x: 123, y: 0.0236 },
      { x: 124, y: 0.02371 },
      { x: 125, y: 0.02382 },
      { x: 126, y: 0.02392 },
      { x: 127, y: 0.02403 },
      { x: 128, y: 0.02414 },
      { x: 129, y: 0.02424 },
      { x: 130, y: 0.02434 },
      { x: 131, y: 0.02444 },
      { x: 132, y: 0.02454 },
      { x: 133, y: 0.02463 },
      { x: 134, y: 0.02473 },
      { x: 135, y: 0.02482 },
      { x: 136, y: 0.02492 },
      { x: 137, y: 0.02501 },
      { x: 138, y: 0.0251 },
      { x: 139, y: 0.02519 },
      { x: 140, y: 0.02527 },
      { x: 141, y: 0.02536 },
      { x: 142, y: 0.02545 },
      { x: 143, y: 0.02553 },
      { x: 144, y: 0.02561 },
      { x: 145, y: 0.02569 },
      { x: 146, y: 0.02577 },
      { x: 147, y: 0.02585 },
      { x: 148, y: 0.02593 },
      { x: 149, y: 0.02601 },
      { x: 150, y: 0.02608 },
    ],
  },
];

export const lineData = [
  {
    id: "CH",
    date: new Date("2020-06-30T00:00:00.000Z"),
    data: [
      { x: 1, y: -0.00737 },
      { x: 2, y: -0.00736 },
      { x: 3, y: -0.00724 },
      { x: 5, y: -0.00642 },
      { x: 7, y: -0.00513 },
      { x: 10, y: -0.00408 },
      { x: 20, y: -0.00427 },
      { x: 30, y: 0.00027 },
      { x: 50, y: 0.00982 },
      { x: 100, y: 0.01857 },
    ],
  },
  {
    id: "CN",
    date: new Date("2020-06-30T00:00:00.000Z"),
    data: [
      { x: 1, y: 0.01792 },
      { x: 2, y: 0.01843 },
      { x: 3, y: 0.01914 },
      { x: 5, y: 0.02083 },
      { x: 7, y: 0.02264 },
      { x: 10, y: 0.02518 },
      { x: 20, y: 0.03133 },
      { x: 30, y: 0.03493 },
      { x: 50, y: 0.03864 },
      { x: 100, y: 0.04179 },
    ],
  },
  {
    id: "GB",
    date: new Date("2020-06-30T00:00:00.000Z"),
    data: [
      { x: 1, y: 0.00097 },
      { x: 2, y: 0.00047 },
      { x: 3, y: 0.00048 },
      { x: 5, y: 0.001 },
      { x: 7, y: 0.00153 },
      { x: 10, y: 0.0023 },
      { x: 20, y: 0.00314 },
      { x: 30, y: 0.00295 },
      { x: 50, y: 0.00217 },
      { x: 100, y: 0.01801 },
    ],
  },
  {
    id: "JP",
    date: new Date("2020-06-30T00:00:00.000Z"),
    data: [
      { x: 1, y: -0.00116 },
      { x: 2, y: -0.00122 },
      { x: 3, y: -0.00127 },
      { x: 5, y: -0.00119 },
      { x: 7, y: -0.00093 },
      { x: 10, y: -0.0003 },
      { x: 20, y: 0.00168 },
      { x: 30, y: 0.00255 },
      { x: 50, y: 0.01263 },
      { x: 100, y: 0.02365 },
    ],
  },
  {
    id: "US",
    date: new Date("2020-06-30T00:00:00.000Z"),
    data: [
      { x: 1, y: 0.00062 },
      { x: 2, y: 0.00024 },
      { x: 3, y: 0.00032 },
      { x: 5, y: 0.00126 },
      { x: 7, y: 0.00262 },
      { x: 10, y: 0.00443 },
      { x: 20, y: 0.00697 },
      { x: 30, y: 0.00732 },
      { x: 50, y: 0.00616 },
      { x: 100, y: 0.02043 },
    ],
  },
];

export const heatMapData = [
  {
    country: "CH",
    "1y": -0.737,
    "2y": -0.736,
    "3y": -0.724,
    "5y": -0.642,
    "7y": -0.513,
    "10y": -0.408,
    "20y": -0.427,
    "30y": 0.027,
    "50y": 0.982,
    "100y": 1.857,
  },
  {
    country: "CN",
    "1y": 1.792,
    "2y": 1.843,
    "3y": 1.914,
    "5y": 2.083,
    "7y": 2.264,
    "10y": 2.518,
    "20y": 3.133,
    "30y": 3.493,
    "50y": 3.864,
    "100y": 4.179,
  },
  {
    country: "GB",
    "1y": 0.097,
    "2y": 0.047,
    "3y": 0.048,
    "5y": 0.1,
    "7y": 0.153,
    "10y": 0.23,
    "20y": 0.314,
    "30y": 0.295,
    "50y": 0.217,
    "100y": 1.801,
  },
  {
    country: "JP",
    "1y": -0.116,
    "2y": -0.122,
    "3y": -0.127,
    "5y": -0.119,
    "7y": -0.093,
    "10y": -0.03,
    "20y": 0.168,
    "30y": 0.255,
    "50y": 1.263,
    "100y": 2.365,
  },
  {
    country: "US",
    "1y": 0.062,
    "2y": 0.024,
    "3y": 0.032,
    "5y": 0.126,
    "7y": 0.262,
    "10y": 0.443,
    "20y": 0.697,
    "30y": 0.732,
    "50y": 0.616,
    "100y": 2.043,
  },
];
